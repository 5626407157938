import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const AgregarLoteComponent = ({ fraccionamientoId, constructoraId, onAddLote }) => {
  const [formData, setFormData] = useState({
    numero_lote: '',
    openings: {
      frente: '',
      derecha: '',
      izquierda: '',
      atras: '',
      ruff: '',
    },
    vinil: '',
    basements: '',
    color: '',
    estado: 'proceso',
    fraccionamiento: fraccionamientoId,
    constructoraId: constructoraId,
    facturado: false,
    fechaEntrega: '', // Nuevo campo que el usuario puede ingresar
  });

  const [imagenes, setImagenes] = useState({
    frente: [],
    derecha: [],
    izquierda: [],
    atras: [],
  });

  const [previewUrls, setPreviewUrls] = useState({
    frente: [],
    derecha: [],
    izquierda: [],
    atras: [],
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOpeningsChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      openings: {
        ...formData.openings,
        [name]: value,
      },
    });
  };

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    const newFiles = Array.from(files); // Convertir FileList a array
  
    // Previsualizar y agregar imágenes
    newFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const maxWidth = 800;
          const maxHeight = 800;
  
          let width = img.width;
          let height = img.height;
  
          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width);
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height);
              height = maxHeight;
            }
          }
  
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
  
          canvas.toBlob(
            (blob) => {
              setImagenes((prev) => ({
                ...prev,
                [name]: [...prev[name], blob],
              }));
              // Actualizar el estado para la vista previa
              const url = URL.createObjectURL(blob);
              setPreviewUrls((prev) => ({
                ...prev,
                [name]: [...prev[name], url],
              }));
            },
            'image/jpeg',
            0.75
          );
        };
      };
      reader.readAsDataURL(file);
    });
  };
  

  // Función para eliminar una imagen
  const handleImageRemove = (side, index) => {
    setImagenes((prev) => {
      const updatedImages = [...prev[side]];
      updatedImages.splice(index, 1);
      return { ...prev, [side]: updatedImages };
    });

    setPreviewUrls((prev) => {
      const updatedPreviews = [...prev[side]];
      updatedPreviews.splice(index, 1);
      return { ...prev, [side]: updatedPreviews };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formDataToSend = new FormData();

    formData.fraccionamiento = fraccionamientoId;
    formData.constructoraId = constructoraId;

    for (const key in formData) {
      if (key === 'openings') {
        for (const openingKey in formData.openings) {
          formDataToSend.append(`openings[${openingKey}]`, formData.openings[openingKey]);
        }
      } else {
        formDataToSend.append(key, formData[key]);
      }
    }
    
    for (const key in imagenes) {
      imagenes[key].forEach((img) => {
        formDataToSend.append(key, img, `${key}.jpg`);
      });
    }
    
    

    try {
      const response = await axios.post(
        `https://back.ivdian.ca/api/constructoras/${formData.constructoraId}/fraccionamientos/${formData.fraccionamiento}/lotes`,
        formDataToSend,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Esta es la clave correcta
          },
        }
      );
      

      if (response.status === 201) {
        console.log('Lote agregado exitosamente');
        onAddLote(response.data);
      } else {
        console.error('Error al agregar el lote');
        onAddLote(response.data);
      }
    } catch (error) {
      console.error('Error al enviar los datos del lote', error.response ? error.response.data : error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-md shadow-md">
      <h3 className="text-2xl font-bold mb-4">Agregar Nuevo Lote</h3>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Formulario para datos del lote */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Número de Lote:</label>
          <input
            type="text"
            name="numero_lote"
            value={formData.numero_lote}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
          />
        </div>

        {/* Inputs para 'openings' */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Openings:</label>
          <div className="flex space-x-4">
            {['frente', 'derecha', 'izquierda', 'atras', 'ruff'].map((side) => (
              <div key={side} className="flex items-center">
                <input
                  type="text"
                  name={side}
                  placeholder={side.charAt(0).toUpperCase() + side.slice(1)}
                  value={formData.openings[side]}
                  onChange={handleOpeningsChange}
                  className="mt-1 p-2 border rounded-md w-full"
                />
              </div>
            ))}
          </div>
        </div>

        {/* Inputs para 'vinil', 'basements', 'color', 'estado' */}
        {['vinil', 'basements', 'color', 'estado'].map((field) => (
          <div key={field}>
            <label className="block text-sm font-medium text-gray-700">{field.charAt(0).toUpperCase() + field.slice(1)}:</label>
            <input
              type="text"
              name={field}
              value={formData[field]}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>
        ))}

        {/* Inputs de imágenes */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Imágenes:</label>
          <div className="space-x-4">
            {['frente', 'derecha', 'izquierda', 'atras'].map((side) => (
              <div key={side} className="flex flex-col">
                <label className="mt-1">{side.charAt(0).toUpperCase() + side.slice(1)}:</label>
                <input
                  type="file"
                  name={side}
                  onChange={handleImageChange}
                  accept="image/*"
                  multiple
                  className="p-2 border rounded-md"
                />
                {previewUrls[side] && (
                  <div className="mt-2">
                    {previewUrls[side].map((url, index) => (
                      <div key={index} className="relative mb-2">
                        <img
                          src={url}
                          alt={`Vista previa de ${side} ${index + 1}`}
                          className="w-full h-auto border rounded-md"
                        />
                        <button
                          type="button"
                          onClick={() => handleImageRemove(side, index)}
                          className="absolute top-1 right-1 text-red-500"
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div>
  <label className="block text-sm font-medium text-gray-700">Fecha de Entrega:</label>
  <input
    type="date"
    name="fechaEntrega"
    value={formData.fechaEntrega}
    onChange={handleChange}
    className="mt-1 p-2 border rounded-md w-full"
  />
</div>
        {/* Botón de submit */}
        <button
          type="submit"
          className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={loading}
        >
          {loading ? (
            <span className="flex items-center justify-center">
              <FontAwesomeIcon icon={faCircleNotch} spin className="mr-2" />
              Cargando...
            </span>
          ) : (
            'Agregar Lote'
          )}
        </button>
      </form>
    </div>
  );
};

export default AgregarLoteComponent;
