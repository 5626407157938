import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'wouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import FramerButton from './FramerButton';
import { motion, AnimatePresence } from 'framer-motion';
import UniqueButton from './UniqueButton';
import Modal from './Modal';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.min.css';
import { AuthContext } from '../context/AuthContext';

alertify.set('notifier', 'position', 'top-right');

const FraccionamientosList = () => {
  const { user } = useContext(AuthContext);
  const [constructoras, setConstructoras] = useState([]);
  const [fraccionamientos, setFraccionamientos] = useState([]);
  const [selectedConstructora, setSelectedConstructora] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nombreFraccionamiento, setNombreFraccionamiento] = useState('');
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [pendingRequests, setPendingRequests] = useState([]);

  // Manejo de conexión online/offline
  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);
  
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
  
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // Guardar solicitudes pendientes en localStorage
  useEffect(() => {
    localStorage.setItem('pendingRequests', JSON.stringify(pendingRequests));
  }, [pendingRequests]);

  // Cargar solicitudes pendientes al iniciar
  useEffect(() => {
    const savedRequests = localStorage.getItem('pendingRequests');
    if (savedRequests) {
      setPendingRequests(JSON.parse(savedRequests));
    }
  }, []);

  useEffect(() => {
    if (isOnline && pendingRequests.length > 0) {
      pendingRequests.forEach(({ url, options }) => {
        fetch(url, options)
          .then(() => {
            setPendingRequests((prev) => prev.slice(1)); // Eliminar solicitud procesada
          })
          .catch((error) => console.error('Error al sincronizar datos', error));
      });
    }
  }, [isOnline, pendingRequests]);

  useEffect(() => {
    fetch('https://back.ivdian.ca/api/constructoras')
      .then(response => response.json())
      .then(data => {
        // Si el usuario existe y NO es admin y tiene constructoras asignadas,
        // filtrar la lista para mostrar solo las constructoras en su array.
        if (user && user.role !== 'admin' && Array.isArray(user.constructoras)) {
          const filtradas = data.filter(constructora =>
            user.constructoras.includes(constructora._id)
          );
          setConstructoras(filtradas);
        } else {
          // Si el usuario es admin, o no tiene un array asignado, se muestran todas.
          setConstructoras(data);
        }
      })
      .catch(error => console.error('Error al obtener la lista de constructoras', error));
  }, [user]);
  
  useEffect(() => {
    // Cargar la lista de fraccionamientos asociados a la constructora seleccionada
    if (selectedConstructora) {
      fetch(`https://back.ivdian.ca/api/constructoras/${selectedConstructora._id}/fraccionamientos`)
        .then((response) => response.json())
        .then((data) => {
          const fraccionamientosFiltrados = data.filter(fraccionamiento =>
            fraccionamiento.constructora === selectedConstructora._id
          );
          setFraccionamientos(fraccionamientosFiltrados);
        })
        .catch((error) => console.error('Error al obtener la lista de fraccionamientos', error));
    } else {
      setFraccionamientos([]);
    }
  }, [selectedConstructora]);

  const handleConstructoraSelection = (constructora) => {
    setSelectedConstructora(constructora);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleCrearFraccionamiento = () => {
    const newFraccionamiento = {
      nombre: nombreFraccionamiento,
      constructora: selectedConstructora._id,
    };
  
    const request = {
      url: `https://back.ivdian.ca/api/constructoras/${selectedConstructora._id}/fraccionamientos`,
      options: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newFraccionamiento),
      },
    };
  
    if (isOnline) {
      fetch(request.url, request.options)
        .then((response) => response.json())
        .then((data) => {
          setFraccionamientos((prev) => [...prev, data]);
        })
        .catch((error) => console.error('Error al crear fraccionamiento', error));
    } else {
      alertify.warning('Estás sin conexión. El fraccionamiento se guardará localmente y se sincronizará más tarde.');
      setPendingRequests((prev) => [...prev, request]);
      setFraccionamientos((prev) => [...prev, newFraccionamiento]);
    }
  
    setIsModalOpen(false);
  };

  return (
    <div className="container fos mx-auto mt-5">
      <h2 className="text-center mb-4 text-2xl">
        {selectedConstructora ? `Fraccionamientos de ${selectedConstructora.nombre}` : 'Lista de Constructoras'}
      </h2>
      {!selectedConstructora && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key="constructoras"
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4"
          >
            {constructoras.map((constructora) => (
              <div key={constructora._id} className="border sms rounded-lg shadow-xl">
                <div className="p-4 text-center">
                  <h5 className="mt-2">{constructora.nombre}</h5>
                  <UniqueButton onClick={() => handleConstructoraSelection(constructora)}>
                    Seleccionar
                  </UniqueButton>
                </div>
              </div>
            ))}
          </motion.div>
        </AnimatePresence>
      )}
      {selectedConstructora && (
        <div>
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="fraccionamientos"
              className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4"
            >
              {Array.isArray(fraccionamientos) && fraccionamientos.length > 0 ? (
                fraccionamientos.map((fraccionamiento) => {
                  let colorClass = 'text-blue-600';
                  return (
                    <div key={fraccionamiento._id} className="border sms rounded-lg shadow-xl">
                      <div className="p-4 text-center">
                        <FontAwesomeIcon icon={faHome} className={`text-3xl ${colorClass}`} />
                        <h5 className="mt-2">{fraccionamiento.nombre}</h5>
                        <FramerButton>
                          <Link
                            to={`/${selectedConstructora._id}/fraccionamientos/${fraccionamiento._id}`}
                            className="btn btn-dark"
                          >
                            Entrar
                          </Link>
                        </FramerButton>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No hay fraccionamientos disponibles.</p>
              )}
            </motion.div>
          </AnimatePresence>
          <FramerButton onClick={handleModalOpen} className="bg-green-500 m-5 p-2 rounded hover:bg-green-600 text-white">
            Crear Fraccionamiento
          </FramerButton>
          <Modal isOpen={isModalOpen} onClose={handleModalClose}>
            <h2 className="text-xl font-semibold mb-4">Crear Fraccionamiento</h2>
            <div className="mb-4">
              <input
                type="text"
                className="form-input w-full p-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-blue-400"
                placeholder="Nombre del Fraccionamiento"
                value={nombreFraccionamiento}
                onChange={(e) => setNombreFraccionamiento(e.target.value)}
              />
              <div className="relative pt-4 pb-3">
                <FramerButton onClick={handleCrearFraccionamiento} className="bg-blue-500 p-2 absolute left-0 rounded hover:bg-blue-600 text-white">
                  agregar
                </FramerButton>
                <FramerButton onClick={handleModalClose} className="bg-red-500 p-2 rounded absolute right-0 hover:bg-red-600 text-white">
                  cancelar
                </FramerButton>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default FraccionamientosList;
