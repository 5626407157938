import React, { useState, useContext } from 'react';
import { Route, Link, Switch, Redirect } from 'wouter';
import './App.css';
import FraccionamientosList from './components/FraccionamientosList';
import FraccionamientoDetails from './components/FraccionamientoDetails';
import LoteDetails from './components/LoteDetails';
import AgregarIncidenciaForm from './components/AgregarIncidenciaForm';
import { Footer } from './components/footer';
import TransitionContainer from './components/TransitionContainer';
import { SoundProvider } from './components/Sound';
import TrabajadorIncidencias from './components/ResumenTrabajadoresPage';
import Materiales from './pages/Materiales';
import Movimientos from './pages/Movimientos';
import Dashboard from './pages/DasboardMetrico';
import { Menu, X } from "lucide-react";
import Login from './pages/Login';
import Register from './pages/Register';
import { AuthProvider, AuthContext } from './context/AuthContext';
import AdminEditUsers from './pages/AdminEditUsers';
import LogoButton from "./components/LogoButton";

function App() {
  return (
    <SoundProvider>
      <AuthProvider>
        <MainApp />
      </AuthProvider>
    </SoundProvider>
  );
}

function MainApp() {
  const { user, logout, loading } = useContext(AuthContext);
  const [menuOpen, setMenuOpen] = useState(false);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900">
        <p className="text-white">Cargando...</p>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900">
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Redirect to="/login" />
        </Switch>
      </div>
    );
  }

  return (
    <div className="container-fluid fos">
      <nav className="bg-gray-900 border-b border-gray-700">
        <div className="container mx-auto p-4">
          <div className="flex justify-between items-center">
            {/* Logo */}
            <LogoButton />
           



            {/* Menú para pantallas grandes */}
            <div className="hidden md:flex space-x-4">
              {user.role === 'admin' && (
                <>
                  <Link className='text-white btn btn-dark' to="/trabajador">Trabajador</Link>
                  <Link className='text-white btn btn-dark' to="/dashboard">Dashboard</Link>
                  <Link className='text-white btn btn-dark' to="/materiales">Materiales</Link>
                  <Link className='text-white btn btn-dark' to="/movimientos">Movimientos</Link>
                  <Link className='text-white btn btn-dark' to="/admin">Admin Usuarios</Link>
                </>
              )}
              {user.role === 'bodega' && (
                <>
                  <Link className='text-white btn btn-dark' to="/materiales">Materiales</Link>
                  <Link className='text-white btn btn-dark' to="/movimientos">Movimientos</Link>
                </>
              )}
              {/* Para usuarios con rol "user" no se muestran estas rutas adicionales */}
              <button onClick={logout} className="text-white btn btn-dark">Cerrar Sesión</button>
            </div>

            {/* Botón del menú en pantallas pequeñas */}
            <button className="md:hidden text-white" onClick={() => setMenuOpen(!menuOpen)}>
              {menuOpen ? <X size={28} /> : <Menu size={28} />}
            </button>
          </div>

          {menuOpen && (
            <div className="md:hidden flex flex-col space-y-2 mt-3 bg-gray-800 p-4 rounded-lg">
              {user.role === 'admin' && (
                <>
                  <Link className='text-white btn btn-dark' to="/trabajador">Trabajador</Link>
                  <Link className='text-white btn btn-dark' to="/dashboard">Dashboard</Link>
                  <Link className='text-white btn btn-dark' to="/materiales">Materiales</Link>
                  <Link className='text-white btn btn-dark' to="/movimientos">Movimientos</Link>
                  <Link className='text-white btn btn-dark' to="/admin">Admin Usuarios</Link>
                </>
              )}
              {user.role === 'bodega' && (
                <>
                  <Link className='text-white btn btn-dark' to="/materiales">Materiales</Link>
                  <Link className='text-white btn btn-dark' to="/movimientos">Movimientos</Link>
                </>
              )}
              <button onClick={logout} className="text-white btn btn-dark">Cerrar Sesión</button>
            </div>
          )}
        </div>
      </nav>

      <div className="container mx-auto mt-4">
        <TransitionContainer>

          <Switch>
            {/* Rutas comunes para todos los roles */}
            <Route path="/" component={FraccionamientosList} />
            <Route path="/:constructoraId/fraccionamientos/:fraccionamientoId" component={FraccionamientoDetails} />
            <Route path="/:constructoraId/fraccionamientos/:fraccionamientoId/:numeroLote" component={LoteDetails} />
            <Route path="/agregar-incidencia" component={AgregarIncidenciaForm} />
            
            {/* Rutas exclusivas para rol "admin" */}
            {user.role === 'admin' && (
              <>
                <Route path="/trabajador" component={TrabajadorIncidencias} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/materiales" component={Materiales} />
                <Route path="/movimientos" component={Movimientos} />
                <Route path="/admin" component={AdminEditUsers} />
              </>
            )}
            
            {/* Rutas exclusivas para rol "bodega" */}
            {user.role === 'bodega' && (
              <>
                <Route path="/materiales" component={Materiales} />
                <Route path="/movimientos" component={Movimientos} />
              </>
            )}
            
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </TransitionContainer>
      </div>

      <Footer />
    </div>
  );
}

export default App;
