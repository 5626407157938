import React, { useState, useContext } from "react";
import { useLocation, Link } from "wouter";
import Alertify from "alertifyjs";
import { AuthContext } from "../context/AuthContext";
import "alertifyjs/build/css/alertify.min.css";
import { Eye, EyeOff } from "lucide-react";

const Login = () => {
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [, setLocation] = useLocation();

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    let emailError = "";
    let passwordError = "";

    if (!validateEmail(email)) {
      emailError = "Correo electrónico inválido";
    }
    if (!validatePassword(password)) {
      passwordError = "La contraseña debe tener al menos 6 caracteres";
    }

    setErrors({ email: emailError, password: passwordError });
    
    if (emailError || passwordError) return;

    try {
      const res = await fetch("https://back.ivdian.ca/api/auth/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error(data.msg || "Error al iniciar sesión");
      }

      login(data.user, data.token);
      Alertify.success("Inicio de sesión exitoso");
    } catch (error) {
      Alertify.error(error.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-96">
        <h2 className="text-white text-2xl font-bold mb-6 text-center">Iniciar Sesión</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="text-white block mb-1">Correo Electrónico</label>
            <input 
              type="email"
              className="w-full p-2 rounded bg-gray-700 text-white border border-gray-600"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
          </div>
          <div className="mb-4 relative">
            <label className="text-white block mb-1">Contraseña</label>
            <div className="relative">
              <input 
                type={showPassword ? "text" : "password"}
                className="w-full p-2 rounded bg-gray-700 text-white border border-gray-600"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                className="absolute inset-y-0 right-2 flex items-center text-white"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
            {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
          </div>
          <button
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            disabled={!email || !password || errors.email || errors.password}
          >
            Iniciar Sesión
          </button>
        </form>
        <p className="text-white text-sm mt-4 text-center">
          ¿No tienes cuenta? <Link to="/register" className="text-blue-400 hover:underline">Regístrate</Link>
        </p>
      </div>
    </div>
  );
};

export default Login;