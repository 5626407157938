// src/pages/AdminEditUsers.js
import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.min.css";
import AgregarConstructoraFraccionamientoForm from "../components/AgregarConstructoraFraccionamientoForm";
import LotesPorFraccionamiento from '../components/LotesPorFraccionamiento';


const AdminEditUsers = () => {
  const { user: currentUser } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [constructoras, setConstructoras] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [selectedConstructoras, setSelectedConstructoras] = useState([]);
  const [selectedRole, setSelectedRole] = useState("user");

  // Obtener token desde localStorage
  const token = localStorage.getItem("token");

  // Obtener todos los usuarios (endpoint: GET /api/auth/users)
  useEffect(() => {
    if (currentUser && currentUser.role === "admin") {
      fetch("https://back.ivdian.ca/api/auth/users", {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error("Error fetching users");
          }
          return res.json();
        })
        .then((data) => {
          if (Array.isArray(data)) {
            setUsers(data);
          } else {
            console.error("La respuesta no es un array:", data);
            setUsers([]);
          }
        })
        .catch((error) => console.error("Error fetching users", error));
    }
  }, [currentUser, token]);

  // Obtener la lista de constructoras (endpoint: GET /api/constructoras)
  useEffect(() => {
    if (currentUser && currentUser.role === "admin") {
      fetch("https://back.ivdian.ca/api/constructoras", {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error("Error fetching constructoras");
          }
          return res.json();
        })
        .then((data) => {
          if (Array.isArray(data)) {
            setConstructoras(data);
          } else {
            console.error("La respuesta de constructoras no es un array:", data);
            setConstructoras([]);
          }
        })
        .catch((error) => console.error("Error fetching constructoras", error));
    }
  }, [currentUser, token]);

  // Actualizar estados al seleccionar un usuario para editar
  useEffect(() => {
    if (editingUser) {
      setSelectedConstructoras(editingUser.constructoras || []);
      setSelectedRole(editingUser.role);
    }
  }, [editingUser]);

  // Funciones para manejar la edición
  const handleEditClick = (user) => {
    setEditingUser(user);
  };

  const handleConstructorasChange = (e) => {
    const options = e.target.options;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selected.push(options[i].value);
      }
    }
    setSelectedConstructoras(selected);
  };

  const handleSave = () => {
    // Enviar tanto el rol como las constructoras asignadas al endpoint PUT
    fetch(`https://back.ivdian.ca/api/auth/${editingUser._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify({ constructoras: selectedConstructoras, role: selectedRole }),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Error al actualizar el usuario");
        return res.json();
      })
      .then((updatedUser) => {
        alertify.success("Usuario actualizado exitosamente");
        setUsers(users.map(u => (u._id === updatedUser._id ? updatedUser : u)));
        setEditingUser(null);
      })
      .catch((error) => {
        console.error(error);
        alertify.error("Error al actualizar el usuario");
      });
  };

  // En lugar de retornar temprano, siempre renderizamos y condicionamos el contenido
  return (
    <div className="container mx-auto mt-8 p-4">
            
      {(!currentUser || currentUser.role !== "admin") ? (
        <div>Acceso denegado. Solo administradores pueden ver esta página.</div>
      ) : (
        <>
          <h1 className="text-2xl font-bold mb-4">Editar Usuarios</h1>
          {users.length === 0 ? (
            <p>No hay usuarios disponibles.</p>
          ) : (
            <table className="min-w-full bg-white border">
              <thead>
                <tr>
                  <th className="py-2 px-4 border">Nombre</th>
                  <th className="py-2 px-4 border">Email</th>
                  <th className="py-2 px-4 border">Rol</th>
                  <th className="py-2 px-4 border">Constructoras Asignadas</th>
                  <th className="py-2 px-4 border">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {users.map(u => (
                  <tr key={u._id}>
                    <td className="py-2 px-4 border">{u.name}</td>
                    <td className="py-2 px-4 border">{u.email}</td>
                    <td className="py-2 px-4 border">{u.role}</td>
                    <td className="py-2 px-4 border">
                      {(u.constructoras || [])
                        .map(constructoraId => {
                          const constructora = constructoras.find(c => c._id === constructoraId);
                          return constructora ? constructora.nombre : "Desconocido";
                        })
                        .join(", ")}
                    </td>
                    <td className="py-2 px-4 border">
                      <button
                        onClick={() => handleEditClick(u)}
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                      >
                        Editar
                      </button>
                    </td>
                  </tr>
                ))}{                  console.log(users)
}
              </tbody>
            </table>
          )}
                <AgregarConstructoraFraccionamientoForm />

                <LotesPorFraccionamiento />
          {editingUser && (
            <div className="mt-8 p-4 border rounded">
              <h2 className="text-xl font-semibold mb-4">Editar usuario: {editingUser.name}</h2>
              <div className="mb-4">
                <label className="block mb-2">Rol</label>
                <select
                  value={selectedRole}
                  onChange={(e) => setSelectedRole(e.target.value)}
                  className="w-full p-2 border rounded"
                >
                  <option value="admin">Admin</option>
                  <option value="bodega">Bodega</option>
                  <option value="user">User</option>
                  <option value="constructora">Constructora</option>
                  <option value="trabajador">Trabajador</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block mb-2">Asignar Constructoras</label>
                <select
                  multiple
                  value={selectedConstructoras}
                  onChange={handleConstructorasChange}
                  className="w-full p-2 border rounded"
                >
                  {constructoras.map(c => (
                    <option key={c._id} value={c._id}>
                      {c.nombre}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mt-4">
                <button onClick={handleSave} className="bg-green-500 text-white px-4 py-2 rounded mr-2">
                  Guardar
                </button>
                <button onClick={() => setEditingUser(null)} className="bg-red-500 text-white px-4 py-2 rounded">
                  Cancelar
                </button>

              </div>

            </div>
            
          )}
        </>
      )}
    </div>
  );
};

export default AdminEditUsers;
