import React, { useEffect, useState } from 'react';
import { useRoute } from 'wouter';
import ResumenTrabajadores from '../components/ResumenTrabajadores';

const ResumenTrabajadoresPage = () => {
  const [, params] = useRoute("/:constructoraId?/fraccionamientos/:fraccionamientoId?/lotes/:loteId?/resumen");
  const [historialIncidencias, setHistorialIncidencias] = useState([]);

  useEffect(() => {
    // Asegurar que params no sea undefined y usar valores por defecto si son nulos
    const constructoraId = params?.constructoraId || "0";
    const fraccionamientoId = params?.fraccionamientoId || "0";
    const loteId = params?.loteId || "0";

    // URL dinámica para obtener todas las incidencias si no hay IDs específicos
    const apiUrl = `https://back.ivdian.ca/api/constructoras/${constructoraId}/fraccionamientos/${fraccionamientoId}/lotes/${loteId}/incidencias`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setHistorialIncidencias(data);
      })
      .catch((error) => {
        console.error("Error al obtener el historial de incidencias", error);
      });
  }, [params]); // Se ejecuta cada vez que los parámetros cambien

  return (
    <div className="container mx-auto mt-6 px-4">
      <h1 className="text-2xl font-bold">Resumen de Incidencias</h1>
      <ResumenTrabajadores historialIncidencias={historialIncidencias} />
    </div>
  );
};

export default ResumenTrabajadoresPage;
