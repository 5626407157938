import axios from "axios";

const API_URL = "https://back.ivdian.ca/api/";

// Materiales
export const getMateriales = () => axios.get(`${API_URL}materiales`);
export const createMaterial = (data) => axios.post(`${API_URL}materiales`, data);

// Movimientos
export const getMovimientos = () => axios.get(`${API_URL}movimientos`);
export const registrarMovimiento = (data) => axios.post(`${API_URL}movimientos`, data);
