import React, { useEffect, useState } from "react";
import axios from "axios";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.min.css";

const API_URL = "https://back.ivdian.ca/api";

export default function Dashboard() {
  const [incidencias, setIncidencias] = useState([]);
  const [materiales, setMateriales] = useState([]);
  const [movimientos, setMovimientos] = useState([]);
  const [trabajadores, setTrabajadores] = useState([]);
  const [trabajadorSeleccionado, setTrabajadorSeleccionado] = useState("");

  useEffect(() => {
    cargarDatos();
  }, []);

  const cargarDatos = async () => {
    try {
      const [resIncidencias, resMateriales, resMovimientos] = await Promise.all([
        axios.get(`${API_URL}/constructoras/1/fraccionamientos/1/lotes/1/incidencias`),
        axios.get(`${API_URL}/materiales`),
        axios.get(`${API_URL}/movimientos`)
      ]);

      setIncidencias(resIncidencias.data);
      setMateriales(resMateriales.data);
      setMovimientos(resMovimientos.data);

      const trabajadoresUnicos = [...new Set(resMovimientos.data.map(mov => mov.nombre))];
      setTrabajadores(trabajadoresUnicos);
    } catch (error) {
      alertify.error("Error al cargar datos");
      console.error("Error al cargar datos", error);
    }
  };

  const movimientosTrabajador = movimientos.filter(mov => mov.nombre === trabajadorSeleccionado);
  const incidenciasRelacionadas = incidencias.filter(inc => inc.trabajador === trabajadorSeleccionado);

  const verificarDevoluciones = () => {
    const salidas = movimientosTrabajador.filter(mov => mov.tipo === "salida");
    const entradas = movimientosTrabajador.filter(mov => mov.tipo === "entrada");
    let alerta = null;
    let detalles = [];

    salidas.forEach((salida) => {
      const entradaCoincidente = entradas.find(entrada => entrada.material === salida.material);
      const materialInfo = materiales.find(m => m._id === salida.material);
      const salchichasPorCaja = materialInfo ? materialInfo.salchichasPorCaja : 0;
      const salchichasTotalesSalida = (salida.cantidadCajas * salchichasPorCaja) + salida.cantidadSalchichas;
      const salchichasTotalesEntrada = entradaCoincidente 
        ? (entradaCoincidente.cantidadCajas * salchichasPorCaja) + entradaCoincidente.cantidadSalchichas
        : 0;
      
      const incidenciasMaterial = incidenciasRelacionadas.filter(
        (inc) => inc.material.marca === salida.material.marca && inc.material.color === salida.material.color
      );

      let salchichasUsadas = 0;
      incidenciasMaterial.forEach(inc => {
        inc.salchichas.forEach(s => {
          salchichasUsadas += s.cantidad;
        });
      });

      if (salchichasTotalesSalida - salchichasTotalesEntrada !== salchichasUsadas) {
        alerta = `⚠️ ${trabajadorSeleccionado} tiene una inconsistencia en las salchichas devueltas.`;
        detalles.push(
          `Llevó: ${salchichasTotalesSalida}, Usó: ${salchichasUsadas}, Devolvió: ${salchichasTotalesEntrada}`
        );
      }
    });

    if (alerta) {
      alertify.alert("Inconsistencia detectada", alerta + "<br>" + detalles.join("<br>"));
    }

    return { alerta, detalles };
  };

  useEffect(() => {
    if (trabajadorSeleccionado) {
      verificarDevoluciones();
    }
  }, [trabajadorSeleccionado]);

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-center">📊 Dashboard de Métricas</h1>

      <div className="mb-6 flex flex-col md:flex-row items-center gap-4">
        <label className="text-lg font-semibold">Selecciona un trabajador:</label>
        <select
          className="border p-2 rounded-lg shadow-sm w-full md:w-64"
          value={trabajadorSeleccionado}
          onChange={(e) => setTrabajadorSeleccionado(e.target.value)}
        >
          <option value="">Todos</option>
          {trabajadores.map((trabajador) => (
            <option key={trabajador} value={trabajador}>{trabajador}</option>
          ))}
        </select>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="p-3">Trabajador</th>
              <th className="p-3">Material</th>
              <th className="p-3">Tipo</th>
              <th className="p-3">Cajas</th>
              <th className="p-3">Salchichas</th>
              <th className="p-3">Incidencias</th>
            </tr>
          </thead>
          <tbody>
            {movimientosTrabajador.length > 0 ? (
              movimientosTrabajador.map((mov) => (
                <tr key={mov._id} className="border-t hover:bg-gray-100 transition">
                  <td className="p-3">{mov.nombre}</td>
                  <td className="p-3">
                    {materiales.find((m) => m.marca === mov.material.marca && m.color === mov.material.color)
                      ? `${mov.material.marca} - ${mov.material.color}`
                      : "Desconocido"}
                  </td>
                  <td className="p-3">{mov.tipo}</td>
                  <td className="p-3 text-center">{mov.cantidadCajas}</td>
                  <td className="p-3 text-center">{mov.cantidadSalchichas}</td>
                  <td className="p-3">
                    {incidenciasRelacionadas.length > 0 ? (
                      <ul className="list-disc pl-4">
                        {incidenciasRelacionadas.map((inc) => (
                          <li key={inc._id} className="text-sm">
                            {inc.titulo} - {inc.material} - {inc.salchichas.map((s) => `${s.color}: ${s.cantidad}`).join(", ")}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      "Ninguna"
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="p-4 text-center text-gray-600">No hay movimientos</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
