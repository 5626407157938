import React, { useState, useEffect } from "react";
import axios from "axios";
import { getMovimientos, registrarMovimiento, getMateriales } from "../api/api";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.min.css";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function Movimientos() {
  // Estados para el listado
  const [movimientos, setMovimientos] = useState([]);
  const [materiales, setMateriales] = useState([]);
  const [trabajadores, setTrabajadores] = useState([]); // Trabajadores con rol "trabajador"
  // Estado para el nuevo movimiento
  const [nuevoMovimiento, setNuevoMovimiento] = useState({
    nombre: "", // Nombre del trabajador seleccionado
    material: "",
    tipo: "entrada",
    cantidadCajas: 0,
    cantidadSalchichas: 0,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    cargarMovimientos();
    cargarMateriales();
    cargarTrabajadores();
  }, []);

  const cargarMovimientos = async () => {
    try {
      const res = await getMovimientos();
      setMovimientos(res.data);
    } catch (error) {
      alertify.error("❌ Error al cargar movimientos");
    }
  };

  const cargarMateriales = async () => {
    try {
      const res = await getMateriales();
      setMateriales(res.data);
    } catch (error) {
      alertify.error("❌ Error al cargar materiales");
    }
  };

  const cargarTrabajadores = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("https://back.ivdian.ca/api/auth/users", {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      });
      // Filtrar solo usuarios con rol "trabajador"
      const trabajadoresFiltrados = response.data.filter(
        (user) => user.role === "trabajador"
      );
      setTrabajadores(trabajadoresFiltrados);
    } catch (error) {
      console.error("Error fetching trabajadores", error);
      alertify.error("❌ Error al cargar trabajadores");
    }
  };

  // Función para generar voucher PDF para un movimiento individual
  const generarVoucher = (movimiento) => {
    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.text("Voucher de Movimiento", 14, 20);
    console.log("Movimiento para voucher:", movimiento);
  
    doc.setFontSize(12);
    doc.text(`Fecha: ${new Date().toLocaleString()}`, 14, 30);
    doc.text(`Trabajador: ${movimiento.nombre || "N/D"}`, 14, 40);
    doc.text(`Tipo: ${movimiento.tipo || "N/D"}`, 14, 50);
  
    // Buscar el material en la lista local y formatearlo como "Nombre (Marca)"
    const materialObj = materiales.find(mat => mat._id === movimiento.material);
    const materialText = materialObj ? `${materialObj.nombre} (${materialObj.marca})` : "N/D";
  
    doc.autoTable({
      startY: 60,
      head: [["Material", "Cajas", "Salchichas"]],
      body: [
        [
          materialText,
          movimiento.cantidadCajas,
          movimiento.cantidadSalchichas,
        ],
      ],
      theme: "grid",
      headStyles: { fillColor: [44, 62, 80] },
    });
  
    doc.save(`voucher_${movimiento.nombre}_${Date.now()}.pdf`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { nombre, material, tipo, cantidadCajas, cantidadSalchichas } = nuevoMovimiento;
  
    if (!nombre.trim() || !material) {
      return alertify.alert("⚠️ Campos requeridos", "Debes seleccionar un trabajador y un material.");
    }
    if (cantidadCajas < 0 || cantidadSalchichas < 0) {
      return alertify.alert("⚠️ Valores inválidos", "Las cantidades no pueden ser negativas.");
    }
  
    if (tipo === "salida") {
      const materialSeleccionado = materiales.find((m) => m._id === material);
      if (!materialSeleccionado) return alertify.error("❌ Material no encontrado.");
  
      alertify.confirm(
        "Confirmar salida",
        "¿Estás seguro de registrar este movimiento de salida?",
        async function () {
          await enviarMovimiento();
        },
        function () {
          alertify.error("❌ Movimiento cancelado");
        }
      );
    } else {
      await enviarMovimiento();
    }
  };

  const enviarMovimiento = async () => {
    try {
      setIsSubmitting(true);
      const res = await registrarMovimiento(nuevoMovimiento);
      console.log("RESPUESTA DEL BACKEND:", res.data);
      // Suponemos que el backend devuelve un objeto con los datos del movimiento
      generarVoucher(res.data);
      alertify.success("✅ Movimiento registrado con éxito");
      cargarMovimientos();
      setNuevoMovimiento({ nombre: "", material: "", tipo: "entrada", cantidadCajas: 0, cantidadSalchichas: 0 });
    } catch (error) {
      console.error("Error registrando movimiento", error);
      alertify.error("❌ Error al registrar el movimiento.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-8xl">
      <h1 className="text-3xl font-bold text-center mb-6">📊 Movimientos</h1>
      <form onSubmit={handleSubmit} className="bg-white p-6 shadow-md rounded-lg mb-6 space-y-4">
        {/* Trabajador: select con usuarios que tienen rol "trabajador" */}
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">Trabajador:</label>
          <select
            value={nuevoMovimiento.nombre}
            onChange={(e) =>
              setNuevoMovimiento({ ...nuevoMovimiento, nombre: e.target.value })
            }
            required
            className="p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
          >
            <option value="">Seleccione un trabajador</option>
            {trabajadores.map((t) => (
              <option key={t._id} value={t.name}>
                {t.name}
              </option>
            ))}
          </select>
        </div>
        {/* Material */}
        <select
          className="border border-gray-300 p-2 w-full rounded-lg"
          value={nuevoMovimiento.material}
          onChange={(e) => setNuevoMovimiento({ ...nuevoMovimiento, material: e.target.value })}
        >
          <option value="">Seleccione material</option>
          {materiales.map((mat) => (
            <option key={mat._id} value={mat._id}>
              {mat.nombre} ({mat.marca})
            </option>
          ))}
        </select>
        {/* Tipo y cantidad de cajas */}
        <div className="grid grid-cols-2 gap-4">
          <select
            className="border border-gray-300 p-2 w-full rounded-lg"
            value={nuevoMovimiento.tipo}
            onChange={(e) => setNuevoMovimiento({ ...nuevoMovimiento, tipo: e.target.value })}
          >
            <option value="entrada">Entrada</option>
            <option value="salida">Salida</option>
          </select>
          <input
            className="border border-gray-300 p-2 w-full rounded-lg"
            type="number"
            placeholder="Cantidad de cajas"
            value={nuevoMovimiento.cantidadCajas || ""}
            onChange={(e) => setNuevoMovimiento({ ...nuevoMovimiento, cantidadCajas: Number(e.target.value) || 0 })}
          />
        </div>
        <input
          className="border border-gray-300 p-2 w-full rounded-lg"
          type="number"
          placeholder="Cantidad de salchichas"
          value={nuevoMovimiento.cantidadSalchichas || ""}
          onChange={(e) => setNuevoMovimiento({ ...nuevoMovimiento, cantidadSalchichas: Number(e.target.value) || 0 })}
        />
        <button
          className="bg-blue-600 text-white px-4 py-2 rounded-lg w-full hover:bg-blue-700 transition"
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Enviando..." : "Registrar Movimiento"}
        </button>
      </form>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-lg rounded-lg text-sm">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="p-3">Trabajador</th>
              <th className="p-3">Material</th>
              <th className="p-3">Tipo</th>
              <th className="p-3">Cajas</th>
              <th className="p-3">Salchichas</th>
              <th className="p-3">Fecha</th>
            </tr>
          </thead>
          <tbody>
            {movimientos.map((mov) => (
              <tr key={mov._id} className="border-t hover:bg-gray-200 transition">
                <td className="p-3">{mov.nombre}</td>
                <td className="p-3">{mov.material.nombre}</td>
                <td className="p-3 font-bold">{mov.tipo}</td>
                <td className="p-3">{mov.cantidadCajas}</td>
                <td className="p-3">{mov.cantidadSalchichas}</td>
                <td className="p-3">{new Date(mov.fecha).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
