import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCog,
  faExclamationTriangle,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../context/AuthContext';
import { Link } from 'wouter';

const LoteList = ({ onClick, constructoraId, fraccionamientoId }) => {
  const { user } = useContext(AuthContext);
  const [lotes, setLotes] = useState([]);
  const [selectedLotes, setSelectedLotes] = useState([]);
  const [totalOpenings, setTotalOpenings] = useState(0);

  useEffect(() => {
    const fetchLotes = async () => {
      try {
        const res = await axios.get(
          `https://back.ivdian.ca/api/constructoras/${constructoraId}/fraccionamientos/${fraccionamientoId}/lotes/fraccionamiento/${fraccionamientoId}`
        );

        const sortedLotes = res.data.sort((a, b) => {
          if (a.estado?.toLowerCase() === 'listo') return 1;
          if (b.estado?.toLowerCase() === 'listo') return -1;
          return a.numero_lote - b.numero_lote;
        });

        setLotes(sortedLotes);
      } catch (error) {
        console.error('Error al obtener los lotes:', error);
      }
    };

    if (fraccionamientoId && constructoraId) fetchLotes();
  }, [constructoraId, fraccionamientoId]);

  const toggleLoteSelection = (numeroLote) => {
    setSelectedLotes((prev) => {
      const updated = prev.includes(numeroLote)
        ? prev.filter((l) => l !== numeroLote)
        : [...prev, numeroLote];

      calculateTotalOpenings(updated);
      return updated;
    });
  };

  const calculateTotalOpenings = (selected) => {
    let total = 0;

    selected.forEach((numeroLote) => {
      const lote = lotes.find((l) => l.numero_lote === numeroLote);
      if (lote && typeof lote.openings === 'object') {
        total += Object.values(lote.openings).reduce((sum, val) => sum + (parseFloat(val) || 0), 0);
      }
    });

    setTotalOpenings(total);
  };

  const getEstadoIcon = (estado) => {
    switch (estado?.toLowerCase()) {
      case 'listo':
        return <FontAwesomeIcon icon={faCheckCircle} className="text-green-600 text-xl" />;
      case 'proceso':
        return <FontAwesomeIcon icon={faCog} className="text-blue-600 text-xl animate-spin" />;
      case 'incidencia':
        return <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-600 text-xl" />;
      default:
        return <FontAwesomeIcon icon={faQuestionCircle} className="text-yellow-600 text-xl" />;
    }
  };

  const toggleFacturado = async (loteId, currentStatus) => {
    try {
      await axios.put(
        `https://back.ivdian.ca/api/constructoras/${constructoraId}/fraccionamientos/${fraccionamientoId}/lotes/${loteId}`,
        { facturado: !currentStatus }
      );
      setLotes((prevLotes) =>
        prevLotes.map((lote) =>
          lote._id === loteId ? { ...lote, facturado: !currentStatus } : lote
        )
      );
    } catch (error) {
      console.error('Error al actualizar facturado:', error);
    }
  };

  return (
    <div className="container mx-auto p-4 max-w-full overflow-auto">
      <h2 className="text-2xl font-bold mb-4">Lotes del Fraccionamiento</h2>
      <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
        <thead>
          <tr className="bg-gray-200">
            <th className="py-2 px-4 border-b border-gray-300 text-left">Lote</th>
            <th className="py-2 px-4 border-b border-gray-300 text-left">Estado</th>
            <th className="py-2 px-4 border-b border-gray-300 text-left">Total Openings</th>
            <th className="py-2 px-4 border-b border-gray-300 text-text">Color</th>
            {user?.role === 'admin' && (
              <th className="py-2 px-4 border-b border-gray-300 text-left">Facturado</th>
            )}
            <th className="py-2 px-4 border-b border-gray-300 text-left">Seleccionar</th>
          </tr>
        </thead>
        <tbody>
          {lotes.map((lote) => {
            const openingsTotal =
              typeof lote.openings === 'object'
                ? Object.values(lote.openings).reduce((sum, val) => sum + (parseFloat(val) || 0), 0)
                : parseFloat(lote.openings) || 0;

            return (
              <tr key={lote._id} className="hover:bg-gray-100 transition duration-200">
                <td className="py-2 inset-shadow-indigo-500/50 px-4">
                  <Link 
                  className="text-gray-900 no-underline hover:shadow-lg rounded-md hover:shadow-gray-400 px-3 py-1 transition-all"
                  onClick={() => onClick(lote.numero_lote)} href={`#${lote.numero_lote}`}>
                    {lote.numero_lote}
                  </Link>
               
                </td>
                <td className="py-2 px-4">{getEstadoIcon(lote.estado)}</td>
                <td className="py-2 px-4">{openingsTotal}</td>
                <td className="py-2 px-4">{lote.color}</td>
                {user?.role === 'admin' && (
                  <td className="py-2 px-4">
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={lote.facturado}
                        onChange={() => toggleFacturado(lote._id, lote.facturado)}
                      />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600 relative"></div>
                    </label>
                  </td>
                )}
                <td className="py-2 px-4">
                  <input
                    className="w-7 h-7 border-2 border-gray-400 rounded-md checked:bg-purple-500 transition-transform duration-200 checked:rotate-12 checked:scale-125"
                    type="checkbox"
                    checked={selectedLotes.includes(lote.numero_lote)}
                    onChange={() => toggleLoteSelection(lote.numero_lote)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <h3 className="text-lg font-semibold mt-4">
        Total Openings Seleccionados: <span className="text-blue-600">{totalOpenings}</span>
      </h3>
    </div>
  );
};

export default LoteList;
