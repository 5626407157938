import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, Link } from 'wouter';
import Alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.min.css';

const AgregarIncidenciaForm = ({ constructoraId, loteId, fraccionamientoId, onClose }) => {
  const [titulo, setTitulo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [trabajador, setTrabajador] = useState('');
  const [openings, setOpenings] = useState('');
  const [salchichas, setSalchichas] = useState([]);
  const [nuevoColor, setNuevoColor] = useState('');
  const [cantidad, setCantidad] = useState('');
  const [material, setMaterial] = useState('');
  const [imagenesPorLado, setImagenesPorLado] = useState({
    frente: [],
    izquierda: [],
    derecha: [],
    atras: [],
  });
  const [lado, setLado] = useState('frente');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [trabajadores, setTrabajadores] = useState([]); // Estado para guardar usuarios con rol "trabajador"

  const materialesDisponibles = ['zeromax', 'tremex', 'pgm', 'downcorni'];

  // Obtener la lista de trabajadores (usuarios con rol "trabajador")
  useEffect(() => {
    axios.get("https://back.ivdian.ca/api/auth/users", {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
    .then(response => {
      // Filtrar solo usuarios con role "trabajador"
      const trabajadoresFiltrados = response.data.filter(user => user.role === "trabajador");
      setTrabajadores(trabajadoresFiltrados);
    })
    .catch(error => {
      console.error("Error fetching trabajadores", error);
    });
  }, []);

  // Manejo de cambio de archivo de imagen
  const handleFileChange = (e) => {
    const files = e.target.files;
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];

    Array.from(files).forEach((file) => {
      if (validImageTypes.includes(file.type)) {
        setImagenesPorLado((prev) => {
          const newImages = { ...prev };
          newImages[lado].push(file);
          return newImages;
        });
      } else {
        alert('Por favor, selecciona un archivo de imagen válido.');
      }
    });
  };

  const agregarSalchicha = () => {
    if (nuevoColor && cantidad) {
      setSalchichas([...salchichas, { color: nuevoColor, cantidad: parseInt(cantidad, 10) }]);
      setNuevoColor('');
      setCantidad('');
    }
  };

  const eliminarSalchicha = (index) => {
    setSalchichas(salchichas.filter((_, i) => i !== index));
  };

  const handleLadoChange = (e) => {
    setLado(e.target.value);
  };

  const handleRemoveImage = (lado, index) => {
    setImagenesPorLado((prev) => {
      const newImages = { ...prev };
      newImages[lado].splice(index, 1);
      return newImages;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setErrorMessage('');

    const formData = new FormData();
    formData.append('titulo', titulo);
    formData.append('descripcion', descripcion);
    formData.append('trabajador', trabajador);
    formData.append('openings', openings);
    formData.append('fraccionamiento', fraccionamientoId);
    formData.append('loteId', loteId);
    formData.append('material', material);
    formData.append('salchichas', JSON.stringify(salchichas));

    // Añadir las imágenes al formData
    Object.keys(imagenesPorLado).forEach((lado) => {
      imagenesPorLado[lado].forEach((img) => {
        formData.append(lado, img);
      });
    });

    try {
      const response = await axios.post(
        `https://back.ivdian.ca/api/constructoras/${constructoraId}/fraccionamientos/${fraccionamientoId}/lotes/${loteId}/incidencias`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        Alertify.success("Incidencia agregada exitosamente");
        onClose();
      } else {
        setErrorMessage('Hubo un problema al agregar la incidencia');
        onClose();
      }
    } catch (error) {
      console.error('Error al agregar la incidencia:', error);
      setErrorMessage('Error al agregar la incidencia');
      onClose();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white shadow-md rounded-lg p-6 max-w-md mx-auto space-y-6"
    >
      <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
        Agregar trabajo realizado por trabajador
      </h2>

      {/* Título */}
      <div className="flex flex-col">
        <label className="text-sm font-medium text-gray-700 mb-1">Título:</label>
        <input
          type="text"
          value={titulo}
          onChange={(e) => setTitulo(e.target.value)}
          required
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          placeholder="Ingresa el título"
        />
      </div>

      {/* Descripción */}
      <div className="flex flex-col">
        <label className="text-sm font-medium text-gray-700 mb-1">Descripción:</label>
        <textarea
          value={descripcion}
          onChange={(e) => setDescripcion(e.target.value)}
          required
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          placeholder="Describe la incidencia"
          rows="4"
        />
      </div>

      {/* Trabajador: ahora se obtienen dinámicamente */}
      <div className="flex flex-col">
        <label className="text-sm font-medium text-gray-700 mb-1">Trabajador:</label>
        <select
          value={trabajador}
          onChange={(e) => setTrabajador(e.target.value)}
          required
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
        >
          <option value="">Selecciona un trabajador</option>
          {trabajadores.map((t) => (
            <option key={t._id} value={t._id}>
              {t.name}
            </option>
          ))}
        </select>
      </div>

      {/* Aperturas */}
      <div className="flex flex-col">
        <label className="text-sm font-medium text-gray-700 mb-1">Aperturas:</label>
        <input
          type="text"
          value={openings}
          onChange={(e) => setOpenings(e.target.value)}
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          placeholder="Número de aperturas"
        />
      </div>
      
      {/* Selección de color */}
      <div className="flex flex-col">
        <label className="text-sm font-medium text-gray-700 mb-1">Color de Salchichas:</label>
        <input
          type="text"
          value={nuevoColor}
          onChange={(e) => setNuevoColor(e.target.value)}
          placeholder="Ej: rojo, azul, verde"
          className="p-2 border border-gray-300 rounded-md"
        />
      </div>

      {/* Cantidad */}
      <div className="flex flex-col">
        <label className="text-sm font-medium text-gray-700 mb-1">Cantidad de salchichas:</label>
        <input
          type="number"
          value={cantidad}
          onChange={(e) => setCantidad(e.target.value)}
          placeholder="Cantidad"
          className="p-2 border border-gray-300 rounded-md"
        />
      </div>

      {/* Botón para agregar salchichas */}
      <button type="button" onClick={agregarSalchicha} className="bg-blue-500 text-white px-3 py-1 rounded-md mt-2">
        Agregar Color
      </button>

      {/* Lista de colores agregados */}
      <ul className="mt-3">
        {salchichas.map((sal, index) => (
          <li key={index} className="flex justify-between bg-gray-100 p-2 rounded-md my-1">
            <span>{sal.color}: {sal.cantidad}</span>
            <button type="button" onClick={() => eliminarSalchicha(index)} className="text-red-500">X</button>
          </li>
        ))}
      </ul>
      
      {/* Material (Select) */}
      <div className="flex flex-col">
        <label className="text-sm font-medium text-gray-700 mb-1">Material:</label>
        <select
          value={material}
          onChange={(e) => setMaterial(e.target.value)}
          required
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
        >
          <option value="">Selecciona un material</option>
          {materialesDisponibles.map((mat) => (
            <option key={mat} value={mat}>
              {mat}
            </option>
          ))}
        </select>
      </div>

      {/* Selector de lado */}
      <div className="flex flex-col">
        <label className="text-sm font-medium text-gray-700 mb-1">Seleccionar lado:</label>
        <select
          value={lado}
          onChange={handleLadoChange}
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
        >
          <option value="frente">Frente</option>
          <option value="izquierda">Izquierda</option>
          <option value="derecha">Derecha</option>
          <option value="atras">Atrás</option>
        </select>
      </div>

      {/* Campo de imagen */}
      <div className="flex flex-col">
        <label className="text-sm font-medium text-gray-700 mb-1">Imagen:</label>
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
        />
      </div>

      {/* Mostrar las imágenes cargadas para cada lado */}
      <div>
        {['frente', 'izquierda', 'derecha', 'atras'].map((side) => (
          <div key={side}>
            <h3>{side.charAt(0).toUpperCase() + side.slice(1)}</h3>
            {imagenesPorLado[side].map((img, index) => (
              <div key={index} className="relative mb-2">
                <img
                  src={URL.createObjectURL(img)}
                  alt={`${side} ${index}`}
                  className="w-20 h-20 object-cover"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveImage(side, index)}
                  className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full text-xs"
                >
                  X
                </button>
              </div>
            ))}
          </div>
        ))}
      </div>

      {/* Mostrar mensaje de error si existe */}
      {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}

      <button
        type="submit"
        disabled={isSubmitting}
        className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 rounded-md transition duration-200"
      >
        {isSubmitting ? 'Enviando...' : 'Agregar Incidencia'}
      </button>
    </form>
  );
};

export default AgregarIncidenciaForm;
