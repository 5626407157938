import { useState, useEffect } from "react";
import { Link } from "wouter";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.min.css";
import logo from "../logo.svg";

const LogoButton = () => {
  const [shadowStyle, setShadowStyle] = useState({
    boxShadow: "0px 0px 0px rgba(255, 255, 255, 0)",
  });
  const [calibration, setCalibration] = useState({ beta: 0, gamma: 0 });

  useEffect(() => {
    const isIOS = /iPhone|iPad/i.test(navigator.userAgent);

    if (isIOS && typeof DeviceMotionEvent.requestPermission === "function") {
      alertify.confirm(
        "Permisos Requeridos",
        "Para activar el efecto interactivo, necesitamos permiso para acceder a los sensores de movimiento.",
        async function () {
          try {
            const permission = await DeviceMotionEvent.requestPermission();
            if (permission === "granted") {
              calibrateSensor();
              startOrientationListener();
            } else {
              alertify.error("Permiso denegado. No se activará el efecto.");
            }
          } catch (error) {
            alertify.error("Error al solicitar permiso.");
          }
        },
        function () {
          alertify.error("Efecto no activado.");
        }
      );
    } else {
      calibrateSensor();
      startOrientationListener();
    }

    return () => {
      window.removeEventListener("deviceorientation", handleOrientation);
    };
  }, []);

  const calibrateSensor = () => {
    window.addEventListener("deviceorientation", (event) => {
      setCalibration({ beta: event.beta, gamma: event.gamma });
    }, { once: true });
  };

  const startOrientationListener = () => {
    window.addEventListener("deviceorientation", handleOrientation);
  };

  const handleOrientation = (event) => {
    const { beta, gamma } = event;

    // Restar la calibración inicial para que el efecto parta de un agarre natural
    const x = (gamma - calibration.gamma) * 1.2;
    const y = (beta - calibration.beta) * 1.2;

    setShadowStyle({
      boxShadow: `${x}px ${y}px 15px rgba(255, 255, 255, 0.8)`,
    });
  };

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - (left + width / 2);
    const y = e.clientY - (top + height / 2);

    setShadowStyle({
      boxShadow: `${x / 5}px ${y / 5}px 15px rgba(255, 255, 255, 0.8)`,
    });
  };

  const handleMouseLeave = () => {
    setShadowStyle({ boxShadow: "0px 0px 0px rgba(255, 255, 255, 0)" });
  };

  return (
    <Link to="/" className="group">
      <div
        className="h-24 w-24 flex items-center justify-center bg-black rounded-md transition-all duration-200 ease-in-out hover:scale-110 hover:rounded-lg active:scale-95"
        style={shadowStyle}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <img 
          src={logo} 
          alt="Logo" 
          className="h-20 w-20 transition-all duration-200 ease-in-out"
        />
      </div>
    </Link>
  );
};

export default LogoButton;
