import React, { useState } from "react";
import { useLocation, Link } from "wouter";
import Alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.min.css";
import { Eye, EyeOff } from "lucide-react";

const Register = () => {
  const [, setLocation] = useLocation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});

  const validateName = (name) => {
    if (name.length < 3) return "El nombre debe tener al menos 3 caracteres.";
    if (!/^[a-zA-Z\s]+$/.test(name)) return "El nombre solo debe contener letras.";
    return "";
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email) ? "" : "Correo electrónico inválido.";
  };

  const validatePassword = (password) => {
    if (password.length < 8) return "Debe tener al menos 8 caracteres.";
    if (!/[A-Z]/.test(password)) return "Debe contener al menos una mayúscula.";
    if (!/[a-z]/.test(password)) return "Debe contener al menos una minúscula.";
    if (!/[0-9]/.test(password)) return "Debe contener al menos un número.";
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) return "Debe incluir un carácter especial.";
    return "";
  };

  const validateConfirmPassword = (confirmPassword) => {
    return confirmPassword === password ? "" : "Las contraseñas no coinciden.";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const nameError = validateName(name);
    const emailError = validateEmail(email);
    const passwordError = validatePassword(password);
    const confirmPasswordError = validateConfirmPassword(confirmPassword);

    if (nameError || emailError || passwordError || confirmPasswordError) {
      setErrors({ name: nameError, email: emailError, password: passwordError, confirmPassword: confirmPasswordError });
      return;
    }

    try {
      const res = await fetch("https://back.ivdian.ca/api/auth/register", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name, email, password }),
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error(data.msg || "Error al registrarse");
      }

      Alertify.success("Registro exitoso. Ahora puedes iniciar sesión.");
      setLocation("/login");
    } catch (error) {
      Alertify.error(error.message.includes("correo") ? "El correo ya está en uso" : error.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-96">
        <h2 className="text-white text-2xl font-bold mb-6 text-center">Registro</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="text-white block mb-1">Nombre</label>
            <input
              type="text"
              className="w-full p-2 rounded bg-gray-700 text-white border border-gray-600"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setErrors({ ...errors, name: validateName(e.target.value) });
              }}
              required
            />
            {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
          </div>
          <div className="mb-4">
            <label className="text-white block mb-1">Correo Electrónico</label>
            <input
              type="email"
              className="w-full p-2 rounded bg-gray-700 text-white border border-gray-600"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrors({ ...errors, email: validateEmail(e.target.value) });
              }}
              required
            />
            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
          </div>
          <div className="mb-4 relative">
            <label className="text-white block mb-1">Contraseña</label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                className="w-full p-2 rounded bg-gray-700 text-white border border-gray-600"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrors({ ...errors, password: validatePassword(e.target.value) });
                }}
                required
              />
              <button type="button" className="absolute inset-y-0 right-2 flex items-center text-white" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
            {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
          </div>
          <div className="mb-4 relative">
            <label className="text-white block mb-1">Confirmar Contraseña</label>
            <div className="relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="w-full p-2 rounded bg-gray-700 text-white border border-gray-600"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setErrors({ ...errors, confirmPassword: validateConfirmPassword(e.target.value) });
                }}
                required
              />
              <button type="button" className="absolute inset-y-0 right-2 flex items-center text-white" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
            {errors.confirmPassword && <p className="text-red-500 text-sm">{errors.confirmPassword}</p>}
          </div>
          <button
            className={`w-full font-bold py-2 px-4 rounded ${
              Object.values(errors).some((error) => error) ? "bg-gray-600 cursor-not-allowed" : "bg-green-600 hover:bg-green-700 text-white"
            }`}
            disabled={Object.values(errors).some((error) => error)}
          >
            Registrarse
          </button>
        </form>
        <p className="text-white text-sm mt-4 text-center">
          ¿Ya tienes cuenta? <Link to="/login" className="text-blue-400 hover:underline">Inicia sesión</Link>
        </p>
      </div>
    </div>
  );
};

export default Register;
