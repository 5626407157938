import React, { useEffect, useState } from "react";
import { getMateriales, createMaterial } from "../api/api";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.min.css";

export default function Materiales() {
  const [materiales, setMateriales] = useState([]);
  const [nuevoMaterial, setNuevoMaterial] = useState({
    nombre: "",
    marca: "",
    tipo: "caulking",
    color: "",
    salchichasPorCaja: 0,
    stockCajas: 0,
    stockSalchichas: 0,
  });

  useEffect(() => {
    cargarMateriales();
  }, []);

  const cargarMateriales = async () => {
    try {
      const res = await getMateriales();
      setMateriales(res.data);
    } catch (error) {
      alertify.error("❌ Error al cargar materiales");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!nuevoMaterial.nombre || !nuevoMaterial.marca) {
      return alertify.alert("⚠️ Campos requeridos", "Nombre y marca son obligatorios.");
    }

    try {
      await createMaterial(nuevoMaterial);
      alertify.success("✅ Material agregado con éxito");
      cargarMateriales();
      setNuevoMaterial({ nombre: "", marca: "", tipo: "caulking", color: "", salchichasPorCaja: 0, stockCajas: 0, stockSalchichas: 0 });
    } catch (error) {
      alertify.error("❌ Error al agregar material");
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-6">
      <h1 className="text-3xl font-bold text-center mb-6">📦 Materiales</h1>
      
      <form onSubmit={handleSubmit} className="bg-white p-6 shadow-lg rounded-lg mb-6 space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <input className="border p-2 rounded-lg w-full" placeholder="Nombre" value={nuevoMaterial.nombre} onChange={(e) => setNuevoMaterial({ ...nuevoMaterial, nombre: e.target.value })} required />
          <input className="border p-2 rounded-lg w-full" placeholder="Marca" value={nuevoMaterial.marca} onChange={(e) => setNuevoMaterial({ ...nuevoMaterial, marca: e.target.value })} required />
        </div>
        <select className="border p-2 rounded-lg w-full" value={nuevoMaterial.tipo} onChange={(e) => setNuevoMaterial({ ...nuevoMaterial, tipo: e.target.value })}>
          <option value="caulking">Caulking</option>
          <option value="backer rod">Backer Rod</option>
          <option value="pistola">Pistola</option>
        </select>
        <input className="border p-2 rounded-lg w-full" placeholder="Color" value={nuevoMaterial.color} onChange={(e) => setNuevoMaterial({ ...nuevoMaterial, color: e.target.value })} />
        <div className="grid grid-cols-2 gap-4">
          <input className="border p-2 rounded-lg w-full" type="number" placeholder="Salchichas por caja" value={nuevoMaterial.salchichasPorCaja || ""} onChange={(e) => setNuevoMaterial({ ...nuevoMaterial, salchichasPorCaja: Number(e.target.value) || 0 })} required />
          <input className="border p-2 rounded-lg w-full" type="number" placeholder="Stock Cajas" value={nuevoMaterial.stockCajas || ""} onChange={(e) => setNuevoMaterial({ ...nuevoMaterial, stockCajas: Number(e.target.value) || 0 })} />
        </div>
        <input className="border p-2 rounded-lg w-full" type="number" placeholder="Stock Salchichas" value={nuevoMaterial.stockSalchichas || ""} onChange={(e) => setNuevoMaterial({ ...nuevoMaterial, stockSalchichas: Number(e.target.value) || 0 })} />
        <button className="bg-blue-600 text-white px-4 py-2 rounded-lg w-full hover:bg-blue-700 transition">Agregar Material</button>
      </form>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {materiales.map((mat) => (
          <div key={mat._id} className="bg-white p-4 shadow-md rounded-lg hover:shadow-xl transition">
            <h2 className="text-xl font-bold">{mat.nombre} ({mat.marca})</h2>
            <p className="text-gray-600">Tipo: {mat.tipo}</p>
            <p className="text-gray-600">Color: {mat.color}</p>
            <p className="text-gray-600">Stock: {mat.stockCajas} cajas, {mat.stockSalchichas} salchichas</p>
          </div>
        ))}
      </div>
    </div>
  );
}