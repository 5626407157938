import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

const ResumenTrabajadores = ({ historialIncidencias }) => {
  const incidenciasPorTrabajador = historialIncidencias.reduce((acc, incidencia) => {
    const { trabajador, openings, salchichas, imagenesPorLado } = incidencia;
    if (!acc[trabajador]) {
      acc[trabajador] = { aperturas: 0, totalSalchichas: 0, imagenes: [] };
    }
    acc[trabajador].aperturas += parseInt(openings, 10) || 0;
    acc[trabajador].totalSalchichas += salchichas?.reduce((sum, s) => sum + s.cantidad, 0) || 0;

    ['frente', 'izquierda', 'derecha', 'atras'].forEach((lado) => {
      if (imagenesPorLado[lado]) {
        acc[trabajador].imagenes.push(...imagenesPorLado[lado]);
      }
    });
    return acc;
  }, {});

  return (
    <div className="bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-xl font-bold mb-4">Resumen de Trabajadores</h2>
      {Object.entries(incidenciasPorTrabajador).map(([trabajador, datos]) => {
        const promedioSalchichas = datos.aperturas > 0 ? (datos.totalSalchichas / datos.aperturas).toFixed(2) : 0;

        return (
          <div key={trabajador} className="mb-6 p-4 border rounded-lg shadow-md">
            <h3 className="text-lg font-semibold">{trabajador}</h3>
            <p className="text-gray-700">Aperturas realizadas: {datos.aperturas}</p>
            <p className="text-gray-700">Total de salchichas usadas: {datos.totalSalchichas}</p>
            <p className="text-gray-700">Promedio de salchichas por apertura: {promedioSalchichas}</p>
            {datos.imagenes.length > 0 && (
              <Swiper navigation={true} modules={[Navigation]} className="w-full mt-4">
                {datos.imagenes.map((img, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={`https://back.ivdian.ca/${img}`}
                      alt={`Imagen ${index + 1}`}
                      className="w-full h-auto max-h-60 object-cover rounded-lg"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ResumenTrabajadores;
