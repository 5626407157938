import React, { useState, useEffect } from 'react';
import axios from 'axios';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.min.css';

 alertify.set('notifier','position', 'top-right');

function AgregarConstructoraFraccionamientoForm({ navigate }) {
  const [nombreConstructora, setNombreConstructora] = useState('');
  const [nombreFraccionamiento, setNombreFraccionamiento] = useState('');
  const [constructoraId, setConstructoraId] = useState(null);
  const [constructoras, setConstructoras] = useState([]);
  const [isConstructoraFormVisible, setConstructoraFormVisible] = useState(true);

  const showSuccessNotification = (message) => {
    alertify.success(message);
  };

  const showErrorNotification = (message) => {
    alertify.error(message);
  };

  const fetchConstructoras = async () => {
    try {
      const response = await axios.get(' https://back.ivdian.ca/api/constructoras');
      setConstructoras(response.data);
    } catch (error) {
      console.error('Error al obtener las constructoras', error);
    }
  };

  useEffect(() => {
    fetchConstructoras();
  }, []);

  const handleAgregarConstructora = async () => {
    if (nombreConstructora.trim() === '') {
      showErrorNotification('Ingresa un nombre de constructora válido');
      return;
    }

    const constructoraExistente = constructoras.find(
      (constructora) => constructora.nombre.toLowerCase() === nombreConstructora.toLowerCase()
    );

    if (constructoraExistente) {
      showErrorNotification('El nombre de la constructora ya está en uso');
      return;
    }

    try {
      // Crear la constructora
      const responseConstructora = await axios.post(' https://back.ivdian.ca/constructoras/agregar', {
        nombre: nombreConstructora,
      });

      const newConstructoraId = responseConstructora.data.insertId;
      setConstructoraId(newConstructoraId);
      setConstructoraFormVisible(false);
      showSuccessNotification('Constructora agregada exitosamente');
    } catch (error) {
      showErrorNotification('Error al agregar la constructora');
    }
  };

  const handleSubmitFraccionamiento = async (e) => {
    e.preventDefault();

    if (constructoraId === null) {
      console.log('Constructora ID es null');
      return;
    }

    try {
      // Verificar si el fraccionamiento ya existe
      const responseFraccionamientos = await axios.get(' https://back.ivdian.ca/fraccionamientos');
      const fraccionamientos = responseFraccionamientos.data;

      const fraccionamientoExistente = fraccionamientos.find(
        (fraccionamiento) =>
          fraccionamiento.constructora_id === constructoraId && fraccionamiento.nombre === nombreFraccionamiento
      );

      if (fraccionamientoExistente) {
        showErrorNotification('El fraccionamiento ya existe');
        return;
      }

      // Agregar el fraccionamiento vinculado a la constructora
      const responseFraccionamiento = await axios.post(' https://back.ivdian.ca/fraccionamientos/agregar', {
        constructoraId,
        nombre: nombreFraccionamiento,
      });

      if (responseFraccionamiento.status === 200) {
        showSuccessNotification('Fraccionamiento agregado exitosamente');
        navigate('/');
      } else {
        showErrorNotification('Error al agregar el fraccionamiento');
      }
    } catch (error) {
      showErrorNotification('Error al agregar el fraccionamiento');
    }
  };

  return (
    <div className="container mt-5">
    <h2 className="mb-4">Agregar Constructora y Fraccionamiento</h2>
    {isConstructoraFormVisible ? (
      <form>
        <div className="mb-3">
          <label className="form-label">Nombre de la Constructora:</label>
          <input
            type="text"
            className="form-control"
            value={nombreConstructora}
            onChange={(e) => setNombreConstructora(e.target.value)}
            required
          />
        </div>
        <button type="button" className="btn btn-primary" onClick={handleAgregarConstructora}>
          Agregar Constructora
        </button>
      </form>
    ) : (
      <form onSubmit={handleSubmitFraccionamiento}>
        <div className="mb-3">
          <label className="form-label">Nombre del Fraccionamiento:</label>
          <input
            type="text"
            className="form-control"
            value={nombreFraccionamiento}
            onChange={(e) => setNombreFraccionamiento(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Agregar Fraccionamiento
        </button>
      </form>
    )}
  </div>
  );
}

export default AgregarConstructoraFraccionamientoForm;