import { useState, useEffect } from "react";


export default function LotesPorFraccionamiento() {
  const [constructoras, setConstructoras] = useState([]);
  const [fraccionamientos, setFraccionamientos] = useState([]);
  const [lotes, setLotes] = useState([]);
  const [selectedConstructora, setSelectedConstructora] = useState("");
  const [selectedFraccionamiento, setSelectedFraccionamiento] = useState("");

  const hstRate = 0.13; // 13% HST

  useEffect(() => {
    fetchConstructoras();
  }, []);

  const fetchConstructoras = async () => {
    const response = await fetch(`https://back.ivdian.ca/api/constructoras`);
    const data = await response.json();
    setConstructoras(data);
  };

  const fetchFraccionamientos = async (constructoraId) => {
    if (!constructoraId) return;
    setFraccionamientos([]);
    setLotes([]);

    const response = await fetch(`https://back.ivdian.ca/api/constructoras/${constructoraId}/fraccionamientos`);
    const data = await response.json();

    // Filtrar fraccionamientos pertenecientes a la constructora
    const fraccionamientosFiltrados = data.filter(f => f.constructora === constructoraId);
    setFraccionamientos(fraccionamientosFiltrados);
  };

  const fetchLotes = async (constructoraId, fraccionamientoId) => {
    const response = await fetch(`https://back.ivdian.ca/api/constructoras/${constructoraId}/fraccionamientos/${fraccionamientoId}/lotes/fraccionamiento/${fraccionamientoId}`);
    const data = await response.json();
    setLotes(data);
  };

  const handleConstructoraChange = (e) => {
    const id = e.target.value;
    setSelectedConstructora(id);
    setSelectedFraccionamiento("");
    fetchFraccionamientos(id);
  };

  const handleFraccionamientoChange = (e) => {
    setSelectedFraccionamiento(e.target.value);
  };

  const handleFetchLotes = () => {
    if (selectedConstructora && selectedFraccionamiento) {
      fetchLotes(selectedConstructora, selectedFraccionamiento);
    }
  };

  // 🟢 FUNCIONALIDAD DRAG & DROP
  const handleDragStart = (e, lote) => {
    e.dataTransfer.setData("loteId", lote._id);
  };

  const handleDrop = async (e, nuevoEstado) => {
    e.preventDefault();
    const loteId = e.dataTransfer.getData("loteId");

    setLotes(prevLotes =>
      prevLotes.map(lote =>
        lote._id === loteId ? { ...lote, facturado: nuevoEstado } : lote
      )
    );

    // 🔵 ACTUALIZAR EN EL BACKEND
    await fetch(`https://back.ivdian.ca/api/constructoras/${selectedConstructora}/fraccionamientos/${selectedFraccionamiento}/lotes/${loteId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ facturado: nuevoEstado }),
    });
  };

   // 🟢 Calcular total de cada categoría
   const totalNoFacturado = lotes
   .filter(l => !l.facturado)
   .reduce((sum, lote) => {
     const totalOpenings = lote.openings ? Object.values(lote.openings).reduce((acc, val) => acc + parseInt(val || 0), 0) : 0;
     return sum + totalOpenings * 17 * (1 + hstRate);
   }, 0);

 const totalFacturado = lotes
   .filter(l => l.facturado)
   .reduce((sum, lote) => {
     const totalOpenings = lote.openings ? Object.values(lote.openings).reduce((acc, val) => acc + parseInt(val || 0), 0) : 0;
     return sum + totalOpenings * 17 * (1 + hstRate);
   }, 0);


  return (
    <div className="p-6">
      <h2 className="text-xl font-semibold mb-4">Lotes por Fraccionamiento</h2>

      <div className="flex gap-4 mb-4">
        <select onChange={handleConstructoraChange} value={selectedConstructora} className="border p-2 rounded">
          <option value="">Seleccione una Constructora</option>
          {constructoras.map((c) => (
            <option key={c._id} value={c._id}>{c.nombre}</option>
          ))}
        </select>

        <select onChange={handleFraccionamientoChange} value={selectedFraccionamiento} className="border p-2 rounded" disabled={!selectedConstructora || fraccionamientos.length === 0}>
          <option value="">Seleccione un Fraccionamiento</option>
          {fraccionamientos.map((f, index) => (
            <option key={f._id || index} value={f._id}>
              {f.nombre}
            </option>
          ))}

        </select>

        <button onClick={handleFetchLotes} className="bg-blue-500 text-white px-4 py-2 rounded" disabled={!selectedFraccionamiento}>
          Buscar Lotes
        </button>
      </div>

      <div className="grid grid-cols-2 gap-4">
        {/* 🟢 Tabla de NO FACTURADOS */}
        <div className="border p-4 bg-red-100" onDragOver={(e) => e.preventDefault()} onDrop={(e) => handleDrop(e, false)}>
          <h3 className="text-lg font-bold mb-2">
              No Facturado <span className="text-gray-700">(${totalNoFacturado.toFixed(2)})</span>
          </h3>
          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 p-2">Lote</th>
                <th className="border border-gray-300 p-2">Total Openings</th>
                <th className="border border-gray-300 p-2">Costo</th>
                <th className="border border-gray-300 p-2">Estado</th>
              </tr>
            </thead>
            <tbody>
              {lotes.filter(l => !l.facturado).map((lote) => {
                const totalOpenings = lote.openings ? Object.values(lote.openings).reduce((acc, val) => acc + parseInt(val || 0), 0) : 0;
                const cost = totalOpenings * 17 * (1 + hstRate);

                return (
                  <tr key={lote.id} className="text-center cursor-pointer" draggable onDragStart={(e) => handleDragStart(e, lote)}>
                    <td className="border border-gray-300 p-2">{lote.numero_lote}</td>
                    <td className="border border-gray-300 p-2">{totalOpenings}</td>
                    <td className="border border-gray-300 p-2">${cost.toFixed(2)}</td>
                    <td className="border border-gray-300 p-2">{lote.estado}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* 🟢 Tabla de FACTURADOS */}
        <div className="border p-4 bg-green-100" onDragOver={(e) => e.preventDefault()} onDrop={(e) => handleDrop(e, true)}>
          <h3 className="text-lg font-bold mb-2">
              Facturado <span className="text-gray-700">(${totalFacturado.toFixed(2)})</span>
            </h3>
          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 p-2">Lote</th>
                <th className="border border-gray-300 p-2">Total Openings</th>
                <th className="border border-gray-300 p-2">Subtotal</th>
                <th className="border border-gray-300 p-2">Total</th>
              </tr>
            </thead>
            <tbody>
              {lotes.filter(l => l.facturado).map((lote) => {
                const totalOpenings = lote.openings ? Object.values(lote.openings).reduce((acc, val) => acc + parseInt(val || 0), 0) : 0;
                const cost = totalOpenings * 17 ;

                return (
                  <tr key={lote.id} className="text-center cursor-pointer" draggable onDragStart={(e) => handleDragStart(e, lote)}>
                    <td className="border border-gray-300 p-2">{lote.numero_lote}</td>
                    <td className="border border-gray-300 p-2">{totalOpenings}</td>
                    <td className="border border-gray-300 p-2">${cost.toFixed(2)}</td>
                    <td className="border border-gray-300 p-2">${(cost * 1.13).toFixed(2)}</td>  {/* Agregar 13% HST */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
